<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'left', sortable: true, text: 'Produtor', value: 'produtor', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: true, text: 'CPF/CNPJ', value: 'cpf_cnpj', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ cellWidth: 21, fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Cód. Produtor Fornecedor', value: 'cod_produtor_fornecedor', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ cellWidth: 16, fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Cód. Produtor Cliente', value: 'cod_produtor_cliente', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ cellWidth: 16, fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Fazenda', value: 'fazenda', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: false, text: 'E-mail', value: 'email', width: 'auto', report: true, groupable: false, styleReport:{ cellWidth: 35, fontSize: 7 } },
          { align: 'left', sortable: false, text: 'Telefone', value: 'telefone', width: 'auto', report: true, groupable: false, styleReport:{ cellWidth: 22, fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Endereço', value: 'endereco', width: 'auto', report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: false, text: 'Nº', value: 'numero', width: 'auto', report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Bairro', value: 'bairro', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Cep', value: 'cep', width: 'auto', report: true, groupable: false, styleReport:{ cellWidth: 16, fontSize: 7 } },
          { align: 'left', sortable: true, text: 'Cidade', value: 'cidade', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ fontSize: 7 } },
          { align: 'left', sortable: true, text: 'UF', value: 'uf', width: 'auto', floatingfilter: true, report: true, groupable: false, styleReport:{ cellWidth: 8, fontSize: 7 } },
        ]
      },
    },
  }
</script>
